import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  cargarDatosPelicula,
  cargarInfoPelicula,
} from "../../../actions/peliculaAction";
import apihost from "../../../config/apiHost.json";

const Pelicula = () => {
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const pelicula = useSelector((state) => state.DatosPelicula.datos.pelicula);
  const infoPelicula = useSelector((state) => state.DatosPelicula.infoPelicula);
  const url_api_front = useSelector(
    (state) => state.DatosComplejo.url_api_front
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [imgPelicula, setImgPelicula] = useState(null);

  useEffect(() => {
    if (isLoading && datosFuncion.cine_id && datosFuncion.funcion_id) {
      dispatch(
        cargarDatosPelicula(
          apihost.REACT_APP_URL_CINEXO,
          datosFuncion.cine_id,
          datosFuncion.funcion_id
        )
      );
    }
  }, [dispatch, isLoading, datosFuncion]);

  useEffect(() => {
    if (pelicula && !infoPelicula) {
      dispatch(
        cargarInfoPelicula(
          apihost.REACT_APP_URL_API_FRONT,
          pelicula.codigo,
          datosFuncion.cine_id
        )
      );
    }
    setIsLoading(false);
  }, [pelicula, datosFuncion, dispatch, infoPelicula]);

  useEffect(() => {
    if (infoPelicula && url_api_front) {
      if (infoPelicula?.peliculas_imagenes) {
        setImgPelicula(infoPelicula?.peliculas_imagenes[0]?.url);
      }
    }
  }, [infoPelicula, url_api_front]);

  console.log(infoPelicula);

  if (!isLoading && pelicula && infoPelicula) {
    return (
      <Box marginBottom="2.5rem">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Box
            padding={0}
            display="flex"
            alignItems="center"
            justifyItems="center"
          >
            <Box
              sx={{
                maxHeight: "20rem",
                width: "auto",
                margin: 0,
                padding: 0,
              }}
              component="img"
              src={
                imgPelicula === null
                  ? `${url_api_front}imagenes/peliculas/NoDisponible.jpg`
                  : imgPelicula
              }
            />
          </Box>
          <Stack
            direction="column"
            spacing="auto"
            justifyContent="flex-start"
            margin={0}
          >
            <Typography
              variant="h1"
              textAlign="left"
              textTransform="uppercase"
              fontSize="2rem"
              fontWeight="700"
              lineHeight="2.2rem"
            >
              {pelicula.pelicula}
            </Typography>
            <Stack spacing={0}>
              <Typography textAlign="left" fontSize="1.3rem">
                Subtitulada: {pelicula.subtitulada === "1" ? "Si" : "No"}
              </Typography>
              <Typography textAlign="left" fontSize="1.3rem">
                Duración: {pelicula.duracion} min
              </Typography>
              <Typography textAlign="left" fontSize="1.3rem">
                Clasificación: {pelicula.clasificacion}
              </Typography>
              <Typography textAlign="left" fontSize="1.3rem">
                Género: {pelicula.genero}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Stack direction="column">
                <Typography
                  textAlign="left"
                  textTransform="uppercase"
                  fontSize="0.8rem"
                  fontWeight="700"
                  paddingBottom="0.2rem"
                >
                  Fecha y hora
                </Typography>
                <Box
                  bgcolor="primary.main"
                  paddingY="0.3rem"
                  paddingX="1.2rem"
                  borderRadius={1}
                >
                  <Typography
                    textAlign="center"
                    textTransform="uppercase"
                    fontSize="1.4rem"
                    fontWeight="700"
                    color="white"
                  >
                    {pelicula.fecha}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="column">
                <Typography
                  textAlign="left"
                  textTransform="uppercase"
                  fontSize="0.8rem"
                  fontWeight="700"
                  paddingBottom="0.2rem"
                >
                  Formato
                </Typography>
                <Box
                  bgcolor="primary.main"
                  paddingY="0.3rem"
                  paddingX="1.2rem"
                  borderRadius={1}
                >
                  <Typography
                    textAlign="center"
                    textTransform="uppercase"
                    fontSize="1.4rem"
                    fontWeight="700"
                    color="white"
                  >
                    {pelicula.is3d}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="column">
                <Typography
                  textAlign="left"
                  textTransform="uppercase"
                  fontSize="0.8rem"
                  fontWeight="700"
                  paddingBottom="0.2rem"
                >
                  Sala
                </Typography>
                <Box
                  bgcolor="primary.main"
                  paddingY="0.3rem"
                  paddingX="1.2rem"
                  borderRadius={1}
                >
                  <Typography
                    textAlign="center"
                    textTransform="uppercase"
                    fontSize="1.4rem"
                    fontWeight="700"
                    color="white"
                  >
                    {pelicula.sala}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Stack alignItems="center" margin="auto" sx={{ marginY: 5 }}>
        <CircularProgress color="secondary" />
      </Stack>
    );
  }
};

export default Pelicula;
