import {
  Box,
  Button,
  FormControl,
  Grid,
  Select,
  Stack,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { confirmarVentaCheckout, ventasTemporales } from "./utils/requests";
import { useDispatch, useSelector } from "react-redux";
import ModalKeyboard from "./ModalKeyboard";
import { banksData } from "./utils/BanksData";
import axios from "axios";
import ModalError from "../Carrito/ModalError";
import ModalSuccess from "../Carrito/ModalSuccess";
import { getImpresionTicketsQRPoint } from "../../utils/impresion";
import { getImpresionFacturaQRPoint } from "../../utils/impresion";
import { updateSubtotal } from "../../actions/carritoActions";
import { setDataToSendConvertion } from "../../actions/paymentActions";
import apihost from "../../config/apiHost.json";

const initialValues = {
  amount: null,
  paymentReference: "",
  telefonoDebito: "",
  origenbank: null,
  trxDate: "",
  prefijo: "",
};

export default function PaymentSitef() {
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const [resVentasTemporales, setResVentasTemporales] = useState(null);
  const [statusPayment, setStatusPayment] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [statusCheckout, setStatusCheckout] = useState(null);
  const complexCode = useSelector((state) => state.DatosFuncion.cine_id);
  const client = useSelector((state) => state.DatosCarrito.dataUsuario);
  const funcion_id = useSelector((state) => state.DatosFuncion.funcion_id);
  const onlyCandy = useSelector((state) => state.DatosCarrito.onlyCandy);
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const datosEmpresa = useSelector((state) => state.DatosComplejo.datosEmpresa);

  const prefijos = ["0416", "0426", "0414", "0424", "0412"];

  useEffect(() => {
    const callVentasTemporales = async () => {
      dispatch(
        setDataToSendConvertion(dataToSend, datosComplejo.tasaConversion)
      );
      let responseVentasTemporales = await ventasTemporales(dataToSend);
      if (responseVentasTemporales && responseVentasTemporales.data.success) {
        setResVentasTemporales(responseVentasTemporales.data);
        dispatch(
          updateSubtotal(
            (
              Number(
                responseVentasTemporales?.data?.data?.sitef?.transactionAmount
              ) * Number(datosComplejo.tasaConversion)
            ).toFixed(2)
          )
        );
      }
    };
    if (!resVentasTemporales) callVentasTemporales();
  }, [dataToSend, resVentasTemporales]);

  //Formulario
  const [paymentData, setPaymentData] = useState(initialValues);

  const onChangeSelect = (e) => {
    e.preventDefault();
    setPaymentData((paymentData) => {
      return {
        ...paymentData,
        origenbank: e.target.value,
      };
    });
  };
  const onChangePrefijo = (e) => {
    e.preventDefault();
    setPaymentData((paymentData) => {
      return {
        ...paymentData,
        prefijo: e.target.value,
      };
    });
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    setPaymentData((paymentData) => {
      return {
        ...paymentData,
        trxDate: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    setError(null);
    setStatusPayment(null);
    e.preventDefault();
    try {
      const ta = Number(resVentasTemporales?.data?.sitef?.transactionAmount);
      const tc = Number(datosComplejo.tasaConversion);
      const formattedAmount = parseFloat((ta * tc).toFixed(2));
      const data = {
        payment: {
          referenceId: resVentasTemporales?.data?.sitef?.referenceId,
          title: "Compra en Librería",
          description: "7613 Pelicula: Spiderman Cantidad Entradas: 2",
          total_amount: formattedAmount,
          items: [
            {
              sku_number: "KS955RUR",
              category: "BOLETERIA",
              title: "Entrada",
              description: "Compra en cine",
              quantity: 1,
              unit_measure: "ticket",
              unit_price: formattedAmount,
              total_amount: formattedAmount,
            },
          ],
          paymentType: "sitef_movil",
          sitefData: {
            idBranch: Number(datosEmpresa.idBranch),
            codeStall: datosEmpresa.cajaSitef,
            amount: formattedAmount,
            paymentReference: paymentData.paymentReference,
            telefonoDebito:
              "0" + paymentData.prefijo + paymentData.telefonoDebito,
            origenbank: paymentData.origenbank,
            trxDate: paymentData.trxDate.split("/").reverse().join("-"),
            receivingBank: 105,
          },
        },
      };
      console.log("RES VENTAS TEMPORALES", resVentasTemporales);
      const responsePaymentStep1 = await axios({
        method: "POST",
        url: `${resVentasTemporales?.data?.sitef?.steps[0].URL}`,
        // url: `${process.env.REACT_APP_URL_DALBE_PAYMENTS}/api/payments/payment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${resVentasTemporales?.data?.sitef?.hash}`,
        },
        data: JSON.stringify(data),
      });
      console.log("STEP 1", responsePaymentStep1);
      if (responsePaymentStep1?.data?.success) {
        if (responsePaymentStep1?.data?.data?.approved) {
          setTotalAmount(
            responsePaymentStep1?.data?.data?.responseGateway.data
              .transaction_list[0].amount
          );
          setId(
            responsePaymentStep1?.data?.data?.responseGateway.data
              .transaction_list[0].payment_reference
          );
          setStatusPayment("approved");
          setStatusCheckout("confirming");
          // Realiza la segunda acción
          let confirmarCheckoutResponse = await confirmarVentaCheckout(
            complexCode,
            resVentasTemporales.data.sitef.referenceId,
            "sitef"
          );
          if (
            confirmarCheckoutResponse &&
            confirmarCheckoutResponse.data &&
            confirmarCheckoutResponse.data.success
          ) {
            setStatusCheckout("success");
            getImpresionTicketsQRPoint(
              paymentData.paymentReference,
              funcion_id,
              complexCode,
              "sitef",
              onlyCandy
            );
            getImpresionFacturaQRPoint(
              paymentData.paymentReference,
              client.nombre,
              complexCode
            );
          } else {
            setStatusCheckout("failed");
          }
        } else {
          setId(paymentData.paymentReference);
          setError(responsePaymentStep1?.data?.data?.errors[0].value);
        }
      } else {
        if (responsePaymentStep1?.data?.errors[0]?.msg) {
          setError(responsePaymentStep1?.data?.data?.errors[0].value);
        }
      }
    } catch (error) {
      console.log(error);
      setError(
        "Lo sentimos, su venta no pudo ser confirmada debido a un error en el sistema."
      );
      setStatusPayment("error");
    }
  };

  //Teclado
  const keyboard = useRef();
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState(null);

  const handleInputClick = (e) => {
    setInputName(e.target.name);
    if (!keyboardVisibility) setKeyboardVisibility(true);
    setPaymentData((paymentData) => {
      return {
        ...paymentData,
        [e.target.name]: "",
      };
    });
  };

  const onChangeAll = (inputs) => {
    setPaymentData((paymentData) => {
      return {
        ...paymentData,
        [inputName]: inputs[inputName],
      };
    });
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    else if (button === "{enter}") {
      setKeyboardVisibility(false);
      handleSubmit();
    }
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    setPaymentData({
      ...paymentData,
      [inputName]: inputVal,
    });
    keyboard.current.setInput(inputVal);
  };

  const renderApproved = () => {
    if (statusCheckout === "confirming") {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Su pago ha sido aprobado, estamos confirmando su compra.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    }
    if (statusCheckout === "success") {
      return (
        <ModalSuccess idOperation={id} totalAmount={totalAmount}></ModalSuccess>
      );
    }
    if (statusCheckout === "failed") {
      return (
        <ModalError
          idOperation={id}
          error={
            "Lo sentimos, su venta no pudo ser confirmada debido a un error en el sistema."
          }
          errorType={"checkout"}
        ></ModalError>
      );
    }
  };

  const renderSitef = () => {
    if (!resVentasTemporales) {
      return (
        <>
          <div>
            <Typography align="center" fontSize={"2rem"}>
              Estamos procesando la información de su pago, le avisaremos cuando
              este listo para ser efectuado.
            </Typography>
            <Grid
              item
              xs={12}
              alignContent={"center"}
              textAlign={"center"}
            ></Grid>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={10}
            >
              <CircularProgress></CircularProgress>
            </Box>
          </div>
        </>
      );
    }
    if (!statusPayment) {
      return (
        <>
          <Box
            sx={{
              maxWidth: "1024px",
              maxHeight: "768px",
              overflow: "auto",
              marginX: "auto",
              paddingX: "1rem",
            }}
          >
            <Stack flexDirection="col" gap={2}>
              <Typography fontSize="1.5rem">
                <Typography
                  fontSize="1.5rem"
                  fontWeight={600}
                  color="secondary.main"
                >
                  IMPORTANTE:{" "}
                </Typography>
                Para completar su compra debe realizar un pago movil al
                siguiente destino:
              </Typography>
              <Box>
                <Typography fontSize="1.5rem" fontWeight={600} marginBottom={1}>
                  Datos del pago
                </Typography>
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "3px",
                    padding: "1rem",
                    maxWidth: "400px",
                    marginX: "auto",
                  }}
                >
                  <Typography fontSize="1.3rem">
                    <b>Entidad Destino: </b>
                    {datosComplejo.nombreBanco}
                  </Typography>
                  <Typography fontSize="1.3rem">
                    <b>Teléfono Beneficiario: </b>
                    {datosComplejo.telefonoPagoMovil}
                  </Typography>
                  <Typography fontSize="1.3rem">
                    <b>Documento de Identificación: </b>
                    {datosComplejo.documentoPagoMovil}
                  </Typography>{" "}
                  <Typography fontSize="1.3rem">
                    <b>Pago: </b>
                    Bs.{" "}
                    {Number(
                      resVentasTemporales?.data?.sitef?.transactionAmount *
                        datosComplejo.tasaConversion
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography fontSize="1.5rem" fontWeight={600}>
                  Formulario
                </Typography>
                <Box
                  sx={{
                    borderRadius: "3px",
                    padding: "1rem",
                    maxWidth: "400px",
                    marginX: "auto",
                  }}
                >
                  <FormControl fullWidth>
                    <Grid container width="auto" spacing={0.5} padding={0}>
                      <Grid item xs={6}>
                        <Stack direction="column" gap={0.5}>
                          <Typography fontSize="1.3rem">
                            Código de pago
                          </Typography>
                          <TextField
                            required
                            color="secondary"
                            placeholder="Código de pago"
                            size="small"
                            inputProps={{
                              style: {
                                fontSize: "1.3rem",
                                backgroundColor: "white",
                              },
                            }}
                            name="paymentReference"
                            value={paymentData.paymentReference}
                            onChange={onChangeInput}
                            onClick={handleInputClick}
                            autoComplete="off"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" gap={0.5}>
                          <Typography fontSize="1.3rem">
                            Prefijo del número
                          </Typography>
                          <Select
                            key={"prefijoTelefono"}
                            required
                            color="secondary"
                            size="small"
                            inputProps={{
                              style: {
                                fontSize: "1.3rem",
                                backgroundColor: "white",
                              },
                            }}
                            name="prefijoTelefono"
                            value={paymentData.prefijo}
                            onChange={onChangePrefijo}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              fontSize: "1.3rem",
                              backgroundColor: "white",
                            }}
                          >
                            <MenuItem
                              key={"prefijoTelefonoOption"}
                              disabled
                              selected
                              sx={{
                                fontSize: "1.3rem",
                              }}
                            >
                              Seleccione el prefijo de su número telefónico
                            </MenuItem>
                            {prefijos?.map((prefijo) => {
                              return (
                                <MenuItem
                                  value={Number(prefijo)}
                                  key={Number(prefijo)}
                                  sx={{
                                    fontSize: "1.3rem",
                                  }}
                                >
                                  {prefijo}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" gap={0.5}>
                          <Typography fontSize="1.3rem">Teléfono</Typography>
                          <TextField
                            required
                            color="secondary"
                            placeholder="Teléfono"
                            size="small"
                            inputProps={{
                              style: {
                                fontSize: "1.3rem",
                                backgroundColor: "white",
                              },
                            }}
                            name="telefonoDebito"
                            value={paymentData.telefonoDebito}
                            onChange={onChangeInput}
                            onClick={handleInputClick}
                            autoComplete="off"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" gap={0.5}>
                          <Typography fontSize="1.3rem">
                            Fecha de pago
                          </Typography>
                          <TextField
                            required
                            color="secondary"
                            type="date"
                            size="small"
                            inputProps={{
                              style: {
                                fontSize: "1.3rem",
                                backgroundColor: "white",
                                textTransform: "uppercase",
                              },
                            }}
                            name="trxDate"
                            value={paymentData.trxDate}
                            onChange={onChangeDate}
                            autoComplete="off"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" gap={0.5}>
                          <Typography fontSize="1.3rem">
                            Banco de origen
                          </Typography>
                          <Select
                            key={"bancoOrigen"}
                            required
                            color="secondary"
                            placeholder="Banco de orígen"
                            size="small"
                            name="origenbank"
                            value={paymentData.origenbank}
                            onChange={onChangeSelect}
                            autoComplete="off"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              fontSize: "1.3rem",
                              backgroundColor: "white",
                            }}
                          >
                            <MenuItem
                              value=""
                              disabled
                              selected
                              sx={{
                                fontSize: "1.3rem",
                              }}
                            >
                              Seleccione el banco con el que realizó el pago
                            </MenuItem>
                            {banksData?.map((bank) => {
                              return (
                                <MenuItem
                                  value={Number(bank.code)}
                                  key={Number(bank.code)}
                                  sx={{
                                    fontSize: "1.3rem",
                                  }}
                                >
                                  {bank.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item marginX="auto" mt={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            height: "100%",
                            paddingX: "25px",
                            fontSize: "1.2rem",
                            fontWeight: "600",
                          }}
                          disableElevation
                          onClick={handleSubmit}
                        >
                          Validar pago
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Box>
              </Box>
            </Stack>
            <>
              {error ? (
                <Typography
                  fontSize="1.3rem"
                  color="error.light"
                  fontWeight="bold"
                  marginX={"auto"}
                  marginY={"0"}
                >
                  {`Error detectado: ${error}`}
                </Typography>
              ) : (
                ""
              )}
            </>
          </Box>
          {keyboardVisibility && (
            <ModalKeyboard
              open={keyboardVisibility}
              setOpen={setKeyboardVisibility}
              keyboard={keyboard}
              inputName={inputName}
              onKeyPress={onKeyPress}
              onChangeAll={onChangeAll}
              layoutName={layoutName}
            />
          )}
        </>
      );
    }
    if (statusPayment === "approved") {
      return renderApproved();
    }
  };

  return renderSitef();
}
