import {
  CARGAR_DATOS_PELICULA,
  CARGAR_INFO_PELICULA,
  RESET_REDUX_STATE,
  SET_ERROR_WS,
} from "../actionTypes/actionTypes";

const cargarDatosPelicula = (url, cine, funcion) => {
  return async function (dispatch) {
    await fetch(
      `${url}funcion/obtenerDatosPorId?complejo_id=${cine}&funcion_id=${funcion}`
    )
      .then((response) => response.json())
      .then((respuesta) => {
        let pelicula = respuesta.datos.at(0);
        return dispatch({
          type: CARGAR_DATOS_PELICULA,
          payload: {
            pelicula,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        // return dispatch({ type: SET_ERROR_WS });
      });
  };
};

const cargarInfoPelicula = (url, pelicula, cine) => {
  return async function (dispatch) {
    await fetch(
      // `${url}pelicula/obtenerDatos?pelicula_id=${pelicula}&complejo_id=${cine}`
      `${url}mobile/consultas/peliculas/DatosPorId?idComplejo=${cine}&id=${pelicula}`
      // `${url}funcion/obtenerDatosPorId?complejo_id=${cine}&funcion_id=${funcion}`
    )
      .then((response) => response.json())
      .then((respuesta) => {
        console.log(respuesta?.data?.datos);
        return dispatch({
          type: CARGAR_INFO_PELICULA,
          payload: {
            data: respuesta?.data?.datos[0],
          },
        });
      })
      .catch((error) => {
        console.log(error);
        return dispatch({ type: SET_ERROR_WS });
      });
  };
};

const peliculaResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

export { cargarDatosPelicula, cargarInfoPelicula, peliculaResetReduxState };
