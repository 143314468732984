import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Button } from "@mui/material";
import posnetGIF from "./images/posnet.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatusPaymentPoint,
  sendRequestIPNDalbe,
  confirmarVentaCheckout,
  getStatusPayment,
} from "./utils/requests";

import ModalSuccess from "../Carrito/ModalSuccess";
import ModalError from "../Carrito/ModalError";
import {
  getImpresionFacturaQRPoint,
  getImpresionTicketsQRPoint,
} from "../../utils/impresion";
import TimerPayment from "../HeadlineTimer/TimerPayment";
import {
  incrementRetry,
  resetPaymentID,
  retryPaymentPoint,
} from "../../actions/paymentActions";
import { useLocation } from "react-router-dom";
import { lockBtnContinue } from "../../actions/carritoActions";
import TimerModal from "../HeadlineTimer/TimerModal";
import { redirect } from "../../utils/helpers";

function PaymentPoint() {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const paymentID = useSelector((state) => state.DatosPayment.paymentID);
  const referenceID = useSelector((state) => state.DatosPayment.referenceID);
  const complexCode = useSelector((state) => state.DatosFuncion.cine_id);
  const funcion_id = useSelector((state) => state.DatosFuncion.funcion_id);
  const client = useSelector((state) => state.DatosCarrito.dataUsuario);
  const deviceId = useSelector((state) => state.DatosPayment.deviceId);
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const retries = useSelector((state) => state.DatosPayment.retries);
  const responseStep1 = useSelector(
    (state) => state.DatosPayment.responseStep1
  );
  const onlyCandy = useSelector((state) => state.DatosCarrito.onlyCandy);
  const [statusPayment, setStatusPayment] = useState("");
  const [paymentIDMP, setpaymentIDMP] = useState("");
  const [paymentProcessStatus, setpaymentProcessStatus] = useState("");
  const [statusPoint, setStatusPoint] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paymentDetail, setPaymentDetail] = useState("");
  const subtype = useSelector((state) => state.DatosPayment.subtype);
  const hash = useSelector((state) => state.DatosPayment.hash);

  const handleRetry = () => {
    dispatch(incrementRetry());
    dispatch(resetPaymentID());
    setTimeout(function () {}, 5000);
    dispatch(retryPaymentPoint(dataToSend, responseStep1, deviceId, hash));
  };
  function redireccionarAURL() {
    redirect(
      datosComplejo.ip_privada,
      datosComplejo.version_boleteria,
      device_id
    );
  }
  const getStatusPWP = () => {
    let intervalId = setInterval(async () => {
      let responseStatusPoint = await getStatusPaymentPoint(
        paymentID,
        complexCode,
        subtype,
        hash
      );
      let status = responseStatusPoint.data.state;
      console.log("status Point Data", responseStatusPoint);
      console.log("status Point", status);
      if (status === "PROCESSING") {
        setStatusPoint("PROCESSING");
      }
      if (status === "CANCELED") {
        clearInterval(intervalId);
        setStatusPoint(status);
      }
      if (status === "FINISHED") {
        dispatch(lockBtnContinue(true));
        clearInterval(intervalId);
        setStatusPoint(status);
        let idPayment = responseStatusPoint.data.payment.id;
        // let responseStatusPayment = await getSatatusPaymentMP(
        //   idPayment,
        //   complexCode
        // );
        let responseStatusPayment = await getStatusPayment(
          complexCode,
          idPayment,
          subtype,
          hash
        );
        let resultPayment = responseStatusPayment.data.data.response.status;
        console.log("status Payment Data", responseStatusPayment);
        console.log("status Payment", resultPayment);
        setpaymentIDMP(responseStatusPayment.data.data.response.id);
        setStatusPayment(responseStatusPayment.data.data.response.status);
        if (resultPayment === "accredited" || resultPayment === "approved") {
          let objDalbe = {
            id: 12345,
            live_mode: true,
            type: "payment",
            date_created: "2015-03-25T10:04:58.396-04:00",
            application_id: "3399761017112789",
            user_id: "3399761017112789",
            version: 1,
            api_version: "v1",
            action: "payment.updated",
            data: {
              id: idPayment,
            },
          };
          let responseIPNDalbe = await sendRequestIPNDalbe(
            referenceID,
            objDalbe,
            complexCode,
            subtype,
            hash
          );
          console.log("Response IPN", responseIPNDalbe);
          let success = responseIPNDalbe.data.success;
          if (success) {
            setpaymentProcessStatus("checkout-confirming");
            let confirmarCheckoutResponse = await confirmarVentaCheckout(
              complexCode,
              referenceID,
              "mercadopago"
            );
            console.log(
              "response confirmar Checkout",
              confirmarCheckoutResponse
            );
            if (
              confirmarCheckoutResponse &&
              confirmarCheckoutResponse.data &&
              (confirmarCheckoutResponse.data.success ||
                confirmarCheckoutResponse.data.status === "procesado")
            ) {
              setpaymentProcessStatus("success");
              getImpresionTicketsQRPoint(
                responseStatusPayment.data.data.response.id,
                funcion_id,
                complexCode,
                "mercadopago",
                onlyCandy
              );
              getImpresionFacturaQRPoint(
                responseStatusPayment.data.data.response.id,
                client.nombre,
                complexCode
              );
            } else {
              setpaymentProcessStatus("failed");
            }
            setTotalAmount(
              `${responseStatusPayment.data.data.response.currency_id} ${responseStatusPayment.data.data.response.transaction_amount}`
            );
          }
        } else {
          setPaymentDetail(
            responseStatusPayment.data.data.response.status_detail
          );
        }
      }
    }, 15000);
    return;
  };

  const renderPoint = () => {
    if (paymentProcessStatus === "failed") {
      return (
        <ModalError
          error={
            "El sistema ha fallado al confirmar la venta. Con tu Número de operacion podrás realizar el reclamo en caja."
          }
          idOperation={paymentIDMP}
          errorType={"checkout"}
        ></ModalError>
      );
    }
    if (statusPoint === "CANCELED" && paymentProcessStatus === "") {
      return (
        <Grid container marginTop="4rem" marginX={0}>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Typography align="center" fontSize={"2rem"} padding={2}>
              El pago ha sido cancelado.
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <TimerModal time={60} />
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => handleRetry()}
            >
              Reintentar
            </Button>
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => {
                redireccionarAURL();
              }}
            >
              Volver al inicio
            </Button>
          </Grid>
        </Grid>
      );
    }
    if (
      statusPoint === "CANCELED" &&
      retries >= 1 &&
      paymentProcessStatus === "time-limit"
    ) {
      return (
        <Grid container marginTop="4rem" marginX={0}>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Typography align="center" fontSize={"2rem"} padding={2}>
              Lo sentimos, has llegado al limite de intentos.
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <TimerModal time={60} />
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => {
                redireccionarAURL();
              }}
            >
              Volver al inicio
            </Button>
          </Grid>
        </Grid>
      );
    }
    if (
      statusPoint === "CANCELED" &&
      retries < 1 &&
      paymentProcessStatus === "time-limit"
    ) {
      return (
        <Grid container marginTop="4rem" marginX={0}>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Typography align="center" fontSize={"2rem"} padding={2}>
              Lo sentimos, has llegado al limite de tiempo.
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <TimerModal time={60} />
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => handleRetry()}
            >
              Reintentar
            </Button>
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => {
                redireccionarAURL();
              }}
            >
              Volver al inicio
            </Button>
          </Grid>
        </Grid>
      );
    }
    if (statusPoint === "PROCESSING") {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Su pago está siendo procesado.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    }
    if (statusPayment === "rejected") {
      return (
        <ModalError
          error={paymentDetail}
          idOperation={paymentIDMP}
        ></ModalError>
      );
    }
    if (statusPayment === "approved" && paymentProcessStatus === "success") {
      return (
        <ModalSuccess
          idOperation={paymentIDMP}
          totalAmount={totalAmount}
        ></ModalSuccess>
      );
    }
    if (
      statusPayment === "" &&
      statusPoint !== "FINISHED" &&
      statusPoint !== "CANCELED"
    ) {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            ¡Su pago ya esta listo para ser procesado en la terminal!
          </Typography>
          <TimerPayment
            data={{
              point: true,
              deviceID: deviceId,
              paymentID: paymentID,
              complexCode: complexCode,
              setpaymentProcessStatus: setpaymentProcessStatus,
              setStatusPoint: setStatusPoint,
            }}
          />
          <Grid container marginTop="4rem" margin={0}>
            <Grid
              id="MP"
              item
              xs={12}
              alignContent="center"
              textAlign={"center"}
            >
              <Box
                component="img"
                alt={`payment`}
                src={posnetGIF}
                alignItems="center"
                textAlign="center"
                borderRadius="0.3rem"
              />
            </Grid>
            {getStatusPWP()}
          </Grid>
        </div>
      );
    }
    if (
      statusPayment === "approved" &&
      paymentProcessStatus === "checkout-confirming"
    ) {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Su pago ha sido aprobado, estamos confirmando su compra.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    }
    if (statusPoint === "FINISHED") {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Estamos comprobando el estado del pago.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    } else {
      return (
        <Typography align="center" fontSize={"2rem"}>
          Su pago ha finalizado.
        </Typography>
      );
    }
  };
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        marginTop: "4rem",
      }}
    >
      {renderPoint()}
    </Box>
  );
}

export default PaymentPoint;
